.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* old style */

/* .mantine-Select-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-TextInput-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-MultiSelect-input {
  min-height: 1.35rem !important;
}

.mantine-MultiSelect-values {}

.mantine-PasswordInput-innerInput {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
  line-height: calc(1.35rem - 0.25rem);
  border-radius: 0.25rem !important;
}

.mantine-Input-rightSection {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-PasswordInput-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-DatePickerInput-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-Textarea-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
} */
/* old */
.custom-text-center .mantine-Text-root {
  text-align: center;
}

.custom-item-right .mantine-Grid-root {
  justify-content: flex-end !important;
}

.custom-margin {
  margin: 15px !important;
}

.custom-btn-title .mantine-Button-root {
  border-radius: 0.25rem 0.25rem 0 0;
}

.custom-text-right .mantine-Grid-col {
  text-align: right;
}

.mantine-NumberInput-rightSection {
  font-size: 10px;
  margin: 5px;
}

.custom-font {
  font-size: 12px !important;
  vertical-align: middle;
}

.custom-width-btn .mantine-Button-root {
  min-width: 3rem !important;
}

input:disabled+.mantine-Switch-track {
  background-color: #228be6;
  border-color: #228be6;
}

.notable-height {
  min-height: 1vh !important;
}

.custom-col .mantine-Grid-col {
  background-color: #f2f2f2;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  border: 1px solid #cfcfcf;
  font-size: 0.6rem;
  border-radius: 5px;
  opacity: 1;
  margin: 2px;
  cursor: pointer;
}

.tab-width .mantine-Tabs-tab {
  width: 110%;
  font-size: 0.65rem;
  padding: 5px;
  line-height: 1rem;
}

.selected-card {
  color: #1c7ed6;
}

.selected-card:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.customborder .mantine-Button-root {
  border-radius: 0 !important;
}

.customborderleft {
  border-top-left-radius: 0.25rem !important;
}

.blue-color {
  color: #1c7ed6;
  font-weight: bold;
}

.qtycard {
  display: inline-block;
  width: "30px";
  text-align: "center";
}

.custom-btn .mantine-Button-root {
  min-width: 0rem !important;
  /* width: 25%; */
}

.rdt_TableRow {
  max-height: max-content !important;
}

.hide-overflow {
  overflow: visible !important;
}

.disabled {
  opacity: 0.5;
  /* or another styling to indicate disabled state */
  pointer-events: none;
  /* to prevent clicking */
}

.red-color {
  color: red;
  font-size: 13px;
}

.red-color2 {
  color: #fa5252;
  font-size: 13px;
}

#fa5252 .device-message {
  color: red;
  font-size: 13px;
  padding-block: 10px;
}

.device-message-two {
  color: red;
  font-size: 10px;
}

.total-amountstyle {
  white-space: nowrap !important;
  font-weight: 700;
  font-size: 15px;
  color: #302F46;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}

.total-amountstyleforaging {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-item-new {
  display: flex;
  justify-content: flex-end;
}

.commontrans {
  padding-bottom: 8px;
}

.commonnametrans {
  /* padding-right: 10px;
  padding-left: 30px; */
  padding-bottom: 8px;
}

.commonnametransmap {
  margin-left: 10px;
  padding-bottom: 8px;
}

.total-amountstylemap {
  font-weight: 700;
  font-size: 15px;
  color: #302F46;
  margin-left: 10px;

}

.font-size-aging {
  font-size: 18px;
}

.generate-code {
  display: flex;
  align-items: flex-end;
}

.custom-fontSingleView {
  font-size: 16px !important;
  vertical-align: middle;
}

.min-scrollareaheight {
  min-height: 200px;
}

.white-nowrap {
  white-space: nowrap !important;
}

.button-apply {
  position: relative;
  left: -14pc;

}

/* receive payment */

.receive-amount-details {
  border: 1px solid #DDDDDD;
  background-color: #EAF3FB;
  border-radius: 6px;
  height: 100%;
  padding: 10px;
}

.amount-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.receive-amount-details>div,
.mantine-DatePickerInput-input {
  white-space: nowrap;
}

.receive-toggle {
  margin-top: 10px;
}

.modal-title-order {
  font-size: 16px !important;
  font-weight: 500;
}

.disabled-input-time :disabled {
  color: #000000 !important
}

.custom-tabs-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}

.custom-tabs-list .mantine-Tabs-tab {
  min-width: 10% !important;
  text-align: center;
}

/* receive payment */


.time-input-html:focus {
  border: 1px solid #228be6 !important;
  outline: none;
  /* Change border color on focus */
}

.time-input-html {
  border-radius: 4px;
  border: 1px solid rgb(241, 243, 245);
  cursor: pointer;
  background-color: rgb(241, 243, 245);
  line-height: 4px;
  padding-left: calc(1.875rem / 3);
  padding-right: calc(1.875rem / 3);
  height: 1.875rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(1.875rem - 0.125rem);
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 0.65rem;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
}

.hour-scroll {
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
}

.minute-scroll {
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
}

.am-pmscroll {
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
}

.status-font {
  display: inline-block;
  font-size: 0.65rem;
  font-weight: 500;
  color: #212529;
  word-break: break-word;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
}

.btn-checkout{
  display: flex;
  justify-content: flex-end;
}

.rc-time-picker-input{
  border-radius: 4px !important;
  border: 1px solid rgb(241, 243, 245) !important;
  cursor: pointer !important;
  background-color: rgb(241, 243, 245) !important;
  line-height: 4px !important;
  padding-left: calc(1.875rem / 3) !important;
  padding-right: calc(1.875rem / 3) !important;
  height: 1.875rem !important;
  -webkit-tap-highlight-color: transparent !important;
  line-height: calc(1.875rem - 0.125rem) !important;
  appearance: none !important;
  resize: none !important;
  box-sizing: border-box !important;
  font-size: 0.65rem !important;
  width: 140% !important;
  color: #000 !important;
  display: block !important;
  text-align: left !important;
  outline: #1c7ed6;
   border-color: #1c7ed6;
}

.rc-time-picker-clear{
  right: -43px !important; 
}